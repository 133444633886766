/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import { useAuth, useTheme } from "~hooks";
import { Button, Go, Image } from "~components";
import { getColor } from "~utils/helpers";
import { MEDIA_QUERIES } from "~utils/css";

import { ReactComponent as Token } from "~assets/icons/token.svg";

const ArticleCardSmall = ({ article }) => {
  // --------------------------------------------------------------------------
  // context / ref / state

  const { account, loggedIn } = useAuth();

  const [isAvailable, setIsAvailable] = useState(null);

  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  // --------------------------------------------------------------------------
  // parsing

  // 'type' refers to Download, Event, etc.
  const { availability, cost, tokenKey, theme, type } = article;

  const isEvent = article?.type?.title?.toLowerCase() === `event`;

  const url = `/${isEvent ? `events` : `library`}/${article.slug.current}`;

  // --------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!account) {
      setIsAvailable(isEvent || article?.availability === -1);
    }

    if (isEvent) {
      setIsAvailable(true);
    } else {
      switch (availability) {
        case -1:
          // it's free
          setIsAvailable(true);
          break;

        case 0:
          // members only
          setIsAvailable(loggedIn);
          break;

        case 1: {
          // premium
          const parsedKeys = account?.purchasedArticles?.split(`,`);

          setIsAvailable(
            parsedKeys?.includes(article?.tokenKey?.toLowerCase())
          );

          break;
        }

        default:
          break;
      }
    }
  }, [account, loggedIn]);

  // --------------------------------------------------------------------------
  // render

  return (
    <article
      css={[
        css`
          ${colourTransitionCSS};

          padding-bottom: 145%;

          background: ${lowImpactMode
            ? colours.background
            : theme.backgroundColour.hex};
          color: ${lowImpactMode
            ? colours.foreground
            : theme.foregroundColour.hex};
        `,
        tw`w-full relative rounded-xl overflow-hidden`
      ]}
    >
      <div
        css={[
          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex flex-col items-stretch`
        ]}
      >
        <header
          css={[
            css`
              flex: 1 1 auto;
            `,
            tw`w-full relative z-20 p-4 border`
          ]}
        >
          <div
            css={[
              tw`w-full relative flex items-center justify-between pb-3 md:pb-4 border-b`
            ]}
          >
            <div css={[tw`relative flex items-center`]}>
              <div
                css={[
                  css`
                    background: ${type?.fontColour?.hex || `transparent`};
                  `,
                  tw`w-4 h-4 relative block mr-2 rounded-full border`
                ]}
              />

              <h3
                css={[
                  css`
                    margin-bottom: -2px;
                  `,
                  tw`font-head text-caption md:text-b3-md uppercase`
                ]}
              >
                {type?.title}
              </h3>
            </div>

            <h4 css={[tw`font-head text-caption md:text-b2-md italic`]}>
              {article.readTime}
            </h4>
          </div>

          <div css={[tw`w-full relative block pt-4 md:pt-6`]}>
            <h2 css={[tw`relative block font-head text-h3 md:text-h4-md`]}>
              {article?.title}
            </h2>
          </div>
        </header>

        {/* // */}

        <div css={[tw`w-full relative block overflow-hidden`]}>
          <figure
            css={[
              css`
                padding-bottom: 100%;
              `,
              tw`w-full relative block`
            ]}
          >
            <div
              css={[
                tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center border-r text-wheat`
              ]}
            >
              {(article?.cardImage && (
                <figure
                  css={[
                    css`
                      .gatsby-image-wrapper,
                      img {
                        max-width: none !important;
                        max-height: none !important;
                      }
                    `,
                    tw`w-full h-full relative flex items-center justify-center`
                  ]}
                >
                  <Image
                    _css={[
                      css`
                        width: 100%;
                        height: 100%;
                        position: relative;
                        display: block;
                        object-fit: cover;
                      `
                    ]}
                    image={article.cardImage}
                    fallbackCSS="width: calc(100% - 2rem);"
                  />
                </figure>
              )) || (
                <h3 css={[tw`font-head text-h3 md:text-h3-md`]}>
                  Image goes here
                </h3>
              )}
            </div>
          </figure>

          <div css={[tw`w-full absolute bottom-0 right-0 left-0 p-4`]}>
            <Go to={url}>
              {(isAvailable && (
                <Button
                  _css={[tw`mt-5 px-4`]}
                  color={article?.theme?.buttonColour || `black`}
                  noborder
                  text="Learn more"
                />
              )) || (
                <>
                  {tokenKey && (
                    <button
                      type="button"
                      css={[
                        css`
                          transition: 0.3s ease opacity;

                          background: ${article?.theme?.buttonColour ||
                          getColor(`black`)};
                          color: ${article?.theme?.buttonColour ===
                          getColor(`charcoal`)
                            ? getColor(`wheat`)
                            : getColor(`black`)};

                          svg {
                            fill: ${article?.theme?.buttonColour ===
                            getColor(`charcoal`)
                              ? getColor(`wheat`)
                              : getColor(`black`)};
                          }

                          ${MEDIA_QUERIES.hoverable} {
                            &:hover {
                              opacity: 0.4;
                            }
                          }
                        `,
                        tw`relative inline-block mt-8 py-3 px-4 rounded-xl overflow-hidden`
                      ]}
                    >
                      <div
                        css={[tw`relative flex items-center justify-between`]}
                      >
                        <Token css={[tw`w-6`]} />

                        <span
                          css={[tw`-mt-1 ml-6 font-body text-b1 md:text-b1-md`]}
                        >
                          {cost}
                        </span>
                      </div>
                    </button>
                  )}
                </>
              )}
            </Go>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ArticleCardSmall;
